const OPAQUE_KEY = "cfg.opaque";

export default {
  namespaced: true,
  state: {
    opaqueBackground: JSON.parse(localStorage.getItem(OPAQUE_KEY) || "false"),
  },
  mutations: {
    setOpaque(state, val) {
      state.opaqueBackground = val;
      localStorage.setItem(OPAQUE_KEY, val);
    },
  },
};
