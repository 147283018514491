import axios from "axios";

export const RIGHT = {
  UPLOAD_PKG: 1,
  MANAGE_ITEMS: 2,
  MANAGE_MODIFIERS: 3,
  MANAGE_GUILD_BONUSES: 5,
  MANAGE_KNOWLEDGE: 6,
  MANAGE_SKILL: 7,
};

export const BONUS = {
  PHYSIC_DEFENCE: 1,
  MAGIC_DEFENCE: 2,
  SOLIDITY: 5,
  PHYSIC_DAMAGE: 8,
  MAGIC_DAMAGE: 9,
  PHYSIC_DAMAGE_PERCENT: 13,
  MAGIC_DAMAGE_PERCENT: 14,
  DODGE: 15,
  ACCURACY: 16,
  CRITICAL_HIT: 17,
  RESILIENCE: 18,
  ATTACK_SPEED: 19,
  PENETRATION: 20,
  BLOCK: 22,
  HEALTH_REGENERATION: 23,
  ENERGY_REGENERATION: 24,
  HEALTH: 25,
  ENERGY: 26,
  HEALTH_PERCENT: 29,
  ENERGY_PERCENT: 31,
  PENETRATION_X2: 45,
  CRITICAL_HIT_X2: 46,
  ACCURACY_X2: 47,
  BLOCK_X2: 48,
  PHYSIC_DEFENCE_PERCENT: 62,
  MAGIC_DEFENCE_PERCENT: 63,
  HEALTH_REGENERATION_PERCENT: 64,
  ENERGY_REGENERATION_PERCENT: 65,
  ATTACK_STRENGTH: 68,
  ATTACK_STRENGTH_X: 77,
  SKILL_COOLDOWN: 39,
  SKILL_COOLDOWN_X2: 100
};

export const TABS = [
  {
    id: 0,
    title: "general",
    bonuses: [25, 26, 23, 24].sort(),
  },
  {
    id: 1,
    title: "attack",
    bonuses: [8, 9, 20, 16, 17, 19, 4, 32, 33, 39, 80, 68, 89].sort(),
  },
  {
    id: 2,
    title: "defence",
    bonuses: [1, 2, -1, -2, 5, 15, 18, 21, 22, 61, 37, 12].sort(),
  },
  {
    id: 3,
    title: "extra",
    bonuses: [-99],
  },
  {
    id: 4,
    title: "rating",
    bonuses: [],
  },
];

export const ITEM_TYPE = {
  0: "melee",
  1: "range",
  2: "body",
  3: "shield",
  4: "cape",
  5: "head",
  6: "belt",
  7: "glove",
  8: "boot",
  9: "amulet",
  10: "ring",
  19: "outfit",
};

// export const PAINT_DELAY = 10000;
// export const NOTIFICATION_DELAY = 15000;
export const PAINT_DELAY = 1;
export const NOTIFICATION_DELAY = 1;

export const TWO_HANDED_SKILLS = [223, 81, 78, 76];

export const BASE_ENERGY = 10000;
export const BASE_ENERGY_REGENERATION = 500;
export const BASE_RESILIENCE = 500;
export const BASE_DODGE = 500;
export const BASE_CRITICAL_HIT = 500;

const API_BASE = "/api";
const API_DATA = `${API_BASE}/data`;

export function getRandomFactionAndClass(locale) {
  return axios.get(`${API_DATA}/class/random/${locale}`);
}

export function getClass(locale, id) {
  return axios.get(`${API_DATA}/class/${locale}/${id}`);
}

export function getMaxLevel() {
  return axios.get(`${API_DATA}/max-level`);
}

export function getBaseHealth(clazz, level) {
  return axios.get(`${API_DATA}/class/health/${clazz}/${level}`);
}

export function getSkillBonuses(skill, level) {
  return axios.get(`${API_DATA}/skill/bonuses/${skill}/${level}`);
}

export function getItemsForSlot(locale, type, level, clazz) {
  return axios.get(`${API_DATA}/item/list/${locale}/${type}/${level}/${clazz}`);
}

export function getItem(locale, item) {
  return axios.get(`${API_DATA}/item/${locale}/${item}`);
}

export function register({ username, email, password, confirm, locale }) {
  return axios.post(`${API_BASE}/user/register`, {
    username,
    email,
    password,
    confirm,
    locale,
  });
}

export function logout() {
  return axios.get(`${API_BASE}/auth/logout`);
}

export function getManageItems(locale) {
  return axios.get(`${API_BASE}/manage/item/list/${locale}`);
}

export function hideItem(item, state) {
  return axios.put(`${API_BASE}/manage/item/hide`, {
    id: item,
    state,
  });
}

export function getModifier(locale, itemType, skill, level, isRune) {
  return axios.get(
    `${API_DATA}/modifier/${
      isRune ? "rune" : "crystal"
    }/${locale}/${itemType}/${skill ? skill : 0}/${level}`
  );
}

export function getExpModifiers() {
  return axios.get(`${API_BASE}/manage/modifier/exp/list`);
}

export function saveExpModifier(model) {
  return axios.post(`${API_BASE}/manage/modifier/exp/save`, model);
}

export function removeExpModifier(id) {
  return axios.delete(`${API_BASE}/manage/modifier/exp/remove/${id}`);
}

export function listBonuses(locale) {
  return axios.get(`${API_BASE}/manage/bonus/list/${locale}`);
}

export function listItemTypeSkills(locale) {
  return axios.get(`${API_BASE}/manage/item/skills/${locale}`);
}

export function getFixModifiers() {
  return axios.get(`${API_BASE}/manage/modifier/fix/list`);
}

export function saveFixModifier(model) {
  return axios.post(`${API_BASE}/manage/modifier/fix/save`, model);
}

export function removeFixModifier(id) {
  return axios.delete(`${API_BASE}/manage/modifier/fix/remove/${id}`);
}

export function saveBuild(build) {
  return axios.post(`${API_BASE}/user/build/save`, build);
}

export function listUserBuilds(locale) {
  return axios.get(`${API_BASE}/user/build/${locale}`);
}

export function listBuilds(locale) {
  return axios.get(`${API_DATA}/build/${locale}`);
}

export function getBonus(locale, id) {
  return axios.get(`${API_DATA}/bonus/${locale}/${id}`);
}

export function getUserBuild(id) {
  return axios.get(`${API_BASE}/user/build/${id}`);
}

export function getPublicBuild(id) {
  return axios.get(`${API_DATA}/build/${id}`);
}

export function listAllGuildBonuses(locale) {
  return axios.get(`${API_BASE}/manage/guild-bonus/list/${locale}`);
}

export function listAllGuildSkills(locale) {
  return axios.get(`${API_BASE}/manage/guild-bonus/levels/${locale}`);
}

export function saveGuildBonus(obj) {
  return axios.post(`${API_BASE}/manage/guild-bonus/save`, obj);
}

export function removeGuildBonus(skill, level, bonus) {
  return axios.delete(
    `${API_BASE}/manage/guild-bonus/remove/${skill}/${level}/${bonus}`
  );
}

export function getGuildBonuses(locale) {
  return axios.get(`${API_DATA}/guild-bonus/${locale}`);
}

export function listFreeBonuses(locale) {
  return axios.get(`${API_DATA}/bonus/list/${locale}`);
}

// export function getAds() {
//   return axios.get("/links.json");
// }

export function listKnowledge(locale, category) {
  return axios.get(`${API_DATA}/knowledge/${locale}/${category}`);
}

export function listAllKnowledge(locale, category) {
  return axios.get(`${API_BASE}/manage/knowledge/${locale}/${category}`);
}

export function listKnowledgeCategories(locale, clazz) {
  return axios.get(`${API_DATA}/knowledge/categories/${locale}/${clazz}`);
}

export function listAllKnowledgeCategories(locale) {
  return axios.get(`${API_BASE}/manage/knowledge/${locale}`);
}

export function getGlobalNotify(locale) {
  return axios.get(`${API_BASE}/user/global/notify/${locale}`);
}

export function getManageSkills(locale) {
  return axios.get(`${API_BASE}/manage/skill/list/${locale}`);
}

export function hideSkill(item, state) {
  return axios.put(`${API_BASE}/manage/skill/hide`, {
    id: item,
    state,
  });
}

export function getManageSkill(lang, id) {
  return axios.get(`${API_BASE}/manage/skill/${lang}/${id}`);
}

export function getSkillLevel(id, level) {
  return axios.get(`${API_DATA}/skill/${id}/${level}`);
}

export function saveSkillBonus(skill, level, bonus, value) {
  return axios.put(
    `${API_BASE}/manage/skill/${skill}/${level}/${bonus}/${value}`
  );
}

export function removeSkillBonus(skill, level, bonus) {
  return axios.delete(`${API_BASE}/manage/skill/${skill}/${level}/${bonus}`);
}

export function getMetric() {
  return axios.get(`${API_BASE}/manage/metric`);
}

export function changePassword(current, next) {
  return axios.post(`${API_BASE}/user/password`, {
    currentPassword: current,
    newPassword: next,
  });
}

export function getProfile() {
  return axios.get(`${API_BASE}/user/profile`);
}

export function getBuildRatings(id) {
  return axios.get(`${API_DATA}/build/rating/${id}`);
}

export function setBuildRating(id, rate) {
  return axios.patch(`${API_BASE}/user/build/rate/${id}/${rate}`);
}

export function getSkills(lang) {
  return axios.get(`${API_DATA}/skill/list/${lang}`);
}

export function getSkill(lang, id) {
  return axios.get(`${API_DATA}/skill/${lang}/${id}`);
}

export function getItemCategories(lang, id) {
  return axios.get(`${API_BASE}/data/item/category/${lang}/${id}`);
}

export function searchItems(lang, query) {
  return axios.get(`${API_BASE}/data/item/search/${lang}/${query}`);
}

export function getCategoryItems(lang, id) {
  return axios.get(`${API_BASE}/data/item/items/${lang}/${id}`);
}

export function removeBuild(id) {
  return axios.delete(`${API_BASE}/user/build/${id}`);
}

export function getSkillsForClass(lang, id) {
  return axios.get(`${API_DATA}/skill/class/${lang}/${id}`);
}

export function getKnowledgeInfo(lang, id, level) {
  return axios.get(`${API_DATA}/knowledge/info/${lang}/${id}/${level}`);
}

export function saveKnowledgeLevel(id, level, gold, knowledge, coeffs) {
  return axios.put(`${API_BASE}/manage/knowledge/level/save`, {
    talentId: id,
    level,
    gold,
    knowledge,
    coefficients: coeffs,
  });
}

export function removeKnowledgeLevel(id, level) {
  return axios.delete(`${API_BASE}/manage/knowledge/level/${id}/${level}`);
}
