import Vue from "vue";
import VueI18n from "vue-i18n";
const req = require.context("./assets/lang", true, /\.json$/im);
const langs = req.keys().map(req);

Vue.use(VueI18n);

const messages = langs.reduce((a, c) => {
  a[c.code] = c.dictionary;
  return a;
}, {});

const gameLang = langs.reduce((a, c) => {
  a[c.code] = c.game;
  return a;
}, {});

const globalDate = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  },
};

const dateTimeFormats = langs.reduce((a, c) => {
  a[c.code] = globalDate;
  return a;
}, {});

export const i18n = new VueI18n({
  locale: langs[0].code,
  messages,
  silentTranslationWarn: false,
  dateTimeFormats,
});

export const available = langs.map((l) => {
  return {
    code: l.code,
    name: l.name,
    game: l.game,
  };
});

export function getUserLang() {
  let code =
    localStorage.getItem("lang") ||
    navigator.language.substr(0, 2).toLowerCase();
  const has = available.find((l) => l.code === code || l.game === code);
  if (has) {
    return has.code;
  }
  return langs[0].code;
}

export function setLang(code) {
  i18n.locale = code;
  localStorage.setItem("lang", code);
}

export function getGameLang(code) {
  return gameLang[code];
}
