<template>
  <div
    class="button"
    :class="{ ok: !disabled }"
    v-text="content"
    @click="click"
  />
</template>

<script>
export default {
  name: "fluid-button",
  props: {
    content: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
  events: {
    click: {
      custom: true,
    },
  },
};
</script>

<style scoped>
.button {
  background-image: url("../assets/button_blue.png");
  background-size: 32px 288px;
  background-position: 0 -144px;
  background-repeat: repeat-x;
  image-rendering: pixelated;
  height: 72px;
  position: relative;
  margin: 8px 32px;
  user-select: none;
  line-height: 72px;
  text-align: center;
  color: #e6ce63;
  font-size: 1.8rem;
}
.button::before,
.button::after {
  content: "";
  position: absolute;
  background-image: url("../assets/button_blue.png");
  background-size: 32px 288px;
  background-repeat: no-repeat;
  width: 32px;
  height: 72px;
  z-index: 1;
}
.button::before {
  left: -31px;
}
.button::after {
  right: -31px;
  transform: scaleX(-1);
}
.button.ok:active {
  background-position: 0 -216px;
}
.button.ok:active::after,
.button.ok:active::before {
  background-position: 0 -72px;
}
</style>
