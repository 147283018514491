import { render, staticRenderFns } from "./fluid-button.vue?vue&type=template&id=dea23cb2&scoped=true&"
import script from "./fluid-button.vue?vue&type=script&lang=js&"
export * from "./fluid-button.vue?vue&type=script&lang=js&"
import style0 from "./fluid-button.vue?vue&type=style&index=0&id=dea23cb2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dea23cb2",
  null
  
)

export default component.exports