<template>
  <div class="wrap" :style="wrapperStyle">
    <!--    <a-->
    <!--      class="rkl side"-->
    <!--      v-if="ads.left !== null"-->
    <!--      :href="ads.left.link"-->
    <!--      :title="ads.left.title"-->
    <!--      :style="{ backgroundImage: `url(${ads.left.img})` }"-->
    <!--      target="_blank"-->
    <!--    />-->
    <!--    <a-->
    <!--      class="rkl vertical"-->
    <!--      v-if="ads.top !== null"-->
    <!--      :href="ads.top.link"-->
    <!--      :title="ads.top.title"-->
    <!--      :style="{ backgroundImage: `url(${ads.top.img})` }"-->
    <!--      target="_blank"-->
    <!--    />-->
    <block-frame :max-width="480">
      <template v-slot:title>
        {{ $t("label.calculator") }}
      </template>
      <div class="base">
        <div class="slots">
          <icon-slot
            type="cape"
            @click.native="openItems(0, 4)"
            :item="slots[0]"
          />
          <icon-slot
            type="helmet"
            @click.native="openItems(1, 5)"
            :item="slots[1]"
          />
          <icon-slot
            type="amulet"
            @click.native="openItems(2, 9)"
            :item="slots[2]"
          />
          <icon-slot
            type="weapon-left"
            @click.native="openItems(3, 1)"
            :item="slots[3]"
          />
          <icon-slot
            type="body"
            @click.native="openItems(4, 2)"
            :item="slots[4]"
          />
          <icon-slot
            type="weapon-right"
            @click.native="openItems(5, 3)"
            :item="slots[5]"
          />
          <icon-slot
            type="outfit"
            @click.native="openItems(6, 19)"
            :item="slots[6]"
          />
          <icon-slot
            type="belt"
            @click.native="openItems(7, 6)"
            :item="slots[7]"
          />
          <icon-slot
            type="glove"
            @click.native="openItems(8, 7)"
            :item="slots[8]"
          />
          <icon-slot
            type="ring"
            @click.native="openItems(9, 10)"
            :item="slots[9]"
          />
          <icon-slot
            type="boots"
            @click.native="openItems(10, 8)"
            :item="slots[10]"
          />
          <icon-slot
            type="ring"
            @click.native="openItems(11, 10)"
            :item="slots[11]"
          />
        </div>
        <div class="segment">
          <player-view />
          <field-label
            v-text="$t('label.level', [level])"
            class="level-label"
            @click.native="levelDialog = true"
          />
          <table class="stats">
            <tr>
              <td>
                <stats-icon name="damage" />
              </td>
              <td></td>
              <td>
                <stats-icon name="defence" />
              </td>
            </tr>
            <tr>
              <td v-text="physicDamage" />
              <td>
                <stats-icon name="physical_dmg" />
              </td>
              <td v-text="physicDefence" />
            </tr>
            <tr>
              <td v-text="magicDamage" />
              <td>
                <stats-icon name="magic_dmg" />
              </td>
              <td v-text="magicDefence" />
            </tr>
          </table>
          <div class="split" />
          <div class="line-stats">
            <stats-icon name="health" />
            <span v-text="health" />
          </div>
          <div class="line-stats">
            <stats-icon name="energy" />
            <span v-text="energy" />
          </div>
        </div>
      </div>
      <div class="low-segment">
        <div class="left">
          <simple-icon
            :icon="faction !== null ? faction.icon : 0"
            @click.native="factionDialog = true"
          />
        </div>
        <div class="middle">
          <div
            class="top"
            v-text="faction !== null ? faction.name : ''"
            @click="factionDialog = true"
          />
          <div
            class="bottom"
            v-text="clazz !== null ? clazz.name : ''"
            @click="classDialog = true"
          />
        </div>
        <div class="right">
          <simple-icon
            :icon="clazz !== null ? clazz.icon : 0"
            @click.native="classDialog = true"
          />
        </div>
      </div>
      <level-dialog
        :max="maxLevel"
        v-if="levelDialog"
        @close="levelDialog = false"
        @select="selectLevel"
      />
      <faction-dialog v-if="factionDialog" @close="factionDialog = false" />
      <class-dialog v-if="classDialog" @close="classDialog = false" />
      <items-dialog
        v-if="itemFilter !== 0"
        :type="itemFilter >> 16"
        @close="itemFilter = 0"
        @select="selectItem"
      />
      <item-dialog
        v-if="viewItem !== null"
        :item="slots[viewItem]"
        @use="updateItem"
        @close="viewItem = null"
        equipped
      />
      <save-dialog
        v-if="saveDialog"
        @close="saveDialog = false"
        :force-new="forceNew"
      />
      <builds-dialog v-if="loadDialog" @close="loadDialog = false" />
      <guild-bonuses-dialog v-if="guildDialog" @close="guildDialog = false" />
      <result-dialog v-if="resultDialog" @close="resultDialog = false" />
      <knowledge-map-dialog
        v-if="knowledgeDialog"
        @close="knowledgeDialog = false"
      />
      <skills-dialog v-if="skillsDialog" @close="skillsDialog = false" />
      <div class="menu">
        <fluid-button
          :content="$t('action.menu')"
          @click="action = true"
          v-show="!isAnyDialog"
        />
        <square-menu
          :items="menu"
          @close="action = false"
          v-if="action"
          @click="click"
        />
        <message-question
          v-if="skillPointsQuestion !== 0"
          @yes="setLevel"
          @no="skillPointsQuestion = 0"
          >{{ $t("msg.limit-skill-points-reset") }}</message-question
        >
      </div>
    </block-frame>
    <!--    <a-->
    <!--      class="rkl side"-->
    <!--      v-if="ads.right !== null"-->
    <!--      :href="ads.right.link"-->
    <!--      :title="ads.right.title"-->
    <!--      :style="{ backgroundImage: `url(${ads.right.img})` }"-->
    <!--      target="_blank"-->
    <!--    />-->
    <!--    <a-->
    <!--      class="rkl vertical step"-->
    <!--      v-if="ads.bottom !== null"-->
    <!--      :href="ads.bottom.link"-->
    <!--      :title="ads.bottom.title"-->
    <!--      :style="{ backgroundImage: `url(${ads.bottom.img})` }"-->
    <!--      target="_blank"-->
    <!--    />-->
  </div>
</template>

<script>
import BlockFrame from "@/components/block-frame";
import IconSlot from "@/components/icon-slot";
import PlayerView from "@/components/player-view";
import FieldLabel from "@/components/field-label";
import StatsIcon from "@/components/stats-icon";
import SimpleIcon from "@/components/simple-icon";
import { mapGetters, mapState } from "vuex";
import { getGameLang } from "@/lang";
import FluidButton from "@/components/fluid-button";
const SquareMenu = () => import("@/components/square-menu");
// import { getAds } from "@/api";
import { getUsedSkillPoints, skillPointsFromLevel } from "@/utils";
const LevelDialog = () =>
  import("@/components/calculator-dialogs/level-dialog");
const FactionDialog = () =>
  import("@/components/calculator-dialogs/faction-dialog");
const ClassDialog = () =>
  import("@/components/calculator-dialogs/class-dialog");
const ItemsDialog = () =>
  import("@/components/calculator-dialogs/items-dialog");
const ItemDialog = () => import("@/components/calculator-dialogs/item-dialog");
const SaveDialog = () => import("@/components/calculator-dialogs/save-dialog");
const BuildsDialog = () =>
  import("@/components/calculator-dialogs/builds-dialog");
const GuildBonusesDialog = () =>
  import("@/components/calculator-dialogs/guild-bonuses-dialog");
const ResultDialog = () =>
  import("@/components/calculator-dialogs/result-dialog");
const KnowledgeMapDialog = () =>
  import("@/components/calculator-dialogs/knowledge-map-dialog");
const SkillsDialog = () =>
  import("@/components/calculator-dialogs/skills-dialog");
const MessageQuestion = () => import("@/components/msg/message-question");

const APP_WIDTH = 588;

export default {
  name: "calculator-page",
  components: {
    MessageQuestion,
    SkillsDialog,
    KnowledgeMapDialog,
    ResultDialog,
    GuildBonusesDialog,
    BuildsDialog,
    SaveDialog,
    SquareMenu,
    FluidButton,
    ItemDialog,
    ItemsDialog,
    ClassDialog,
    FactionDialog,
    LevelDialog,
    // MessageLoader,
    SimpleIcon,
    StatsIcon,
    FieldLabel,
    PlayerView,
    IconSlot,
    BlockFrame,
  },
  data() {
    return {
      levelDialog: false,
      factionDialog: false,
      classDialog: false,
      itemFilter: 0,
      viewItem: null,
      action: false,
      saveDialog: false,
      loadDialog: false,
      guildDialog: false,
      resultDialog: false,
      knowledgeDialog: false,
      skillsDialog: false,
      // ads: {
      //   left: null,
      //   top: null,
      //   right: null,
      //   bottom: null,
      // },
      forceNew: false,
      skillPointsQuestion: 0,
      windowSize: null,
    };
  },
  mounted() {
    if (this.clazz === null) {
      setTimeout(() => {
        this.$store.dispatch("calculator/initialize", getGameLang(this.locale));
      }, 1);
    }
    // getAds().then((e) => {
    //   this.ads = e.data;
    // });
    window.addEventListener(
        "resize",
        () => (this.windowSize = window.outerWidth)
    );
    this.windowSize = window.outerWidth;
  },
  computed: {
    ...mapState("calculator", [
      "clazz",
      "faction",
      "loading",
      "level",
      "maxLevel",
      "slots",
      "build",
      "originalBuild",
      "skills",
    ]),
    locale() {
      return this.$route.params.lang;
    },
    ...mapGetters("calculator", [
      "health",
      "energy",
      "physicDamage",
      "magicDamage",
      "physicDefence",
      "magicDefence",
    ]),
    menu() {
      const res = [];
      res.push({
        id: 1,
        icon: "save",
        title: this.$t("action.save"),
        disabled: false,
      });
      if (this.build !== 0) {
        res.push({
          id: 2,
          icon: "save-new",
          title: this.$t("action.save-new"),
          disabled: false,
        });
      }
      res.push({
        id: 3,
        icon: "load",
        title: this.$t("action.load"),
        disabled: false,
      });
      res.push({
        id: 4,
        icon: "skills",
        title: this.$t("action.skills"),
        disabled: false,
      });
      res.push({
        id: 5,
        icon: "result",
        title: this.$t("action.result"),
        disabled: false,
      });
      res.push({
        id: 6,
        icon: "knowledge",
        title: this.$t("action.knowledge"),
        disabled: false,
      });
      res.push({ id: 7, icon: "guild", title: this.$t("action.guild") });
      if (this.originalBuild > 0) {
        res.push({
          id: 8,
          icon: "comments",
          title: this.$t("action.comments"),
          disabled: true,
        });
      }
      res.push({
        id: 9,
        icon: "effects",
        title: this.$t("action.buffs"),
        disabled: true,
      });
      return res;
    },
    isAnyDialog() {
      return (
        this.levelDialog ||
        this.factionDialog ||
        this.classDialog ||
        this.saveDialog ||
        this.loadDialog ||
        this.guildDialog ||
        this.resultDialog ||
        this.knowledgeDialog ||
        this.skillsDialog ||
        this.itemFilter !== 0 ||
        this.viewItem !== null
      );
    },
    wrapperStyle() {
      if (!this.windowSize || this.windowSize > APP_WIDTH) {
        return {};
      }
      const ratio = this.windowSize / APP_WIDTH;
      const offset = -(APP_WIDTH - this.windowSize) / 2;
      return {
        left: "auto",
        right: `${offset}px`,
        transform: `scale(${ratio})`,
      };
    },
  },
  methods: {
    setLevel() {
      this.$store.commit("calculator/resetSkills");
      this.$store.commit("calculator/setLevel", this.skillPointsQuestion);
      this.$store.dispatch("calculator/getBaseHealth", {
        clazz: this.clazz.id,
        level: this.skillPointsQuestion,
      });
      this.skillPointsQuestion = 0;
    },
    selectLevel(i) {
      if (getUsedSkillPoints(this.skills.value) > skillPointsFromLevel(i)) {
        this.skillPointsQuestion = i;
      } else {
        this.$store.commit("calculator/setLevel", i);
        this.$store.dispatch("calculator/getBaseHealth", {
          clazz: this.clazz.id,
          level: i,
        });
      }
    },
    openItems(slot, type) {
      if (this.slots[slot] === null) {
        this.itemFilter = (type << 16) | slot;
      } else {
        this.viewItem = slot;
      }
    },
    selectItem(item) {
      this.$store.commit("calculator/setSlot", {
        slot: this.itemFilter & 0xffff,
        item,
      });
    },
    updateItem(item) {
      this.$store.commit("calculator/setSlot", {
        slot: this.viewItem,
        item, // basically null
      });
    },
    click(i) {
      switch (i) {
        case 1:
          this.save();
          break;
        case 2:
          this.forceNew = true;
          this.saveDialog = true;
          break;
        case 3:
          this.loadDialog = true;
          break;
        case 4:
          this.skillsDialog = true;
          break;
        case 5:
          this.resultDialog = true;
          break;
        case 6:
          this.knowledgeDialog = true;
          break;
        case 7:
          this.guildDialog = true;
          break;
        default:
          console.warn("[Menu] Missing button handler");
      }
    },
    save() {
      if (this.slots.reduce((a, c) => a + (c ? 1 : 0), 0) <= 2) {
        return;
      }
      this.forceNew = false;
      this.saveDialog = true;
    },
  },
};
</script>

<style scoped>
.wrap {
  padding: 8px;
}
.base {
  display: flex;
}
.slots {
  display: flex;
  flex-wrap: wrap;
  width: 270px;
}
.segment {
  background-color: rgba(73, 56, 47, 0.6);
  border: 2px solid #ada56b;
  border-radius: 8px;
  width: 160px;
}
.level-label {
  text-align: center;
  display: block;
}
.stats {
  width: 100%;
  text-align: center;
  color: #fff;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  font-weight: bold;
  font-size: 1.2rem;
}
.stats tr,
.stats tr > td {
  height: 32px;
  line-height: 0;
}
.stats tr > td:first-child,
.stats tr > td:last-child {
  width: 64px;
}
.split {
  border: 1px solid #ada56b;
}
.line-stats {
  color: #fff;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 14px;
  margin: 4px;
  display: flex;
}
.line-stats > span {
  line-height: 28px;
  flex-grow: 1;
  margin-left: 8px;
  padding-top: 4px;
}
.low-segment {
  background-color: rgba(73, 56, 47, 0.6);
  border-top: 2px solid #ada56b;
  border-left: 2px solid #ada56b;
  border-right: 2px solid #ada56b;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 80px;
  margin-top: 8px;
  margin-bottom: -23px;
  display: flex;
  flex-direction: row;
}
.low-segment > .left,
.low-segment > .right {
  width: 80px;
  flex: 0 0 80px;
}
.low-segment > .left,
.low-segment > .middle > .top {
  background-color: rgba(73, 56, 47, 0.6);
}
.low-segment > .middle {
  flex-grow: 1;
}
.low-segment > .middle > div {
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
  font-weight: bold;
}
.low-segment > .middle > .bottom {
  border-left: 2px solid #ada56b;
  border-top: 1px solid #ada56b;
  border-right: 2px solid transparent;
}
.low-segment > .middle > .top {
  border-right: 2px solid #ada56b;
  border-bottom: 1px solid #ada56b;
  border-left: 2px solid transparent;
}

/*noinspection CssUnusedSymbol*/
.low-segment > div > .icon {
  margin: 8px;
}
.menu {
  position: absolute;
  bottom: -84px;
  left: 0;
  width: 100%;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
/*noinspection CssUnusedSymbol*/
.frame {
  margin: 0;
}
.rkl {
  margin: 4px;
}
.rkl.side {
  width: 172px;
  height: 420px;
}
.rkl.vertical {
  width: 305px;
  height: 120px;
}
.rkl.side {
  display: none;
}
.rkl.vertical {
  display: block;
}
.rkl.vertical.step {
  margin-top: 80px;
}
@media screen and (min-width: 826px) {
  .wrap {
    flex-direction: row;
  }
  .rkl.side {
    display: block;
  }
  .rkl.vertical {
    display: none;
  }
}
</style>
